import React from 'react';
import type {
  CategoryCarouselProps,
  CategoryCarouselPropsCard,
  Control,
} from '@noths/polaris-client-ribbons-design-system';
import { CategoryCarousel } from '@noths/polaris-client-ribbons-design-system';

import { SectionContainer } from 'src/components/molecules/SectionContainer/SectionContainer';
import type { CategoryCarouselCategorySerializedData } from 'src/content/sections/categoryCarousel/types';
import { trackCarouselNavigation } from 'src/tracking/common/carousel';
import { trackItemsImpression } from 'src/tracking/common/trackItemsImpression';
import { trackLinkClick } from 'src/tracking/common/trackLinkClick';
import type { TrackingContext } from 'src/types/TrackingContext';
import * as styles from './CategoryCarouselSection.style';

export interface CategoryCarouselSectionProps extends Omit<CategoryCarouselProps, 'categories'> {
  categories: CategoryCarouselCategorySerializedData[];
  trackingPosition?: number;
  trackingTitle: string;
}

export const CategoryCarouselSection = ({
  categories,
  title,
  trackingPosition,
  trackingTitle,
}: CategoryCarouselSectionProps) => {
  const trackingContext: TrackingContext = {
    trackingPosition,
    trackingTitle,
    trackingContentType: 'Category carousel',
  };

  return (
    <SectionContainer columnsXL={10} maxWidth="max" wrapperStyles={styles.wrapper}>
      <CategoryCarousel
        categories={categories}
        onCategoryClick={(e, { index }) => {
          const { categoryName } = categories[index];

          trackLinkClick(e, {
            ...trackingContext,
            link: {
              index,
              name: categoryName,
              href: e.currentTarget.href,
            },
            numberOfItems: categories.length,
            actionName: 'Category click',
          });
        }}
        onNavigation={(swiped: boolean, control: Control) => {
          const direction = control === 'next' ? 'next' : 'previous';

          trackCarouselNavigation(swiped, direction, {
            ...trackingContext,
            numberOfItems: categories.length,
          });
        }}
        onVisible={() => {
          if (categories.length > 0) {
            trackItemsImpression({
              ...trackingContext,
              numberOfItems: categories.length,
            });
          }
        }}
        title={title}
      />
    </SectionContainer>
  );
};

export type { CategoryCarouselProps, Control, CategoryCarouselPropsCard };
